<template>
  <div class="card-body">
    <v-data-table
      :loading="isLoading"
      v-model="selected"
      show-select
      :single-select="singleSelect"
      :headers="headers"
      :items="mainAssets"
      no-data-text="Malumotlar topilmadi!"
      loading-text="Malumotlar yuklanmoqda..."
      hide-default-footer
      class="elevation-1"
    >
      <template v-slot:[`item.action`]="{ item }">
        <Action :index="item"></Action>
      </template>
    </v-data-table>
    <div class="text-left mt-5">
      <v-pagination
        v-model="currentPage"
        :total-visible="7"
        @input="getPostData(currentPage)"
        :length="Math.ceil(getCount.count / 10)"
      ></v-pagination>
    </div>
  </div>
  <!-- </div> -->
</template>
<script>
import Action from './Action'
export default {
  components: { Action },
  data: () => ({
    selected: [],
    singleSelect: false,
    perPage: 10,
    currentPage: 1
  }),
  created() {
    this.currentPage = parseInt(this.$route.params.id, 10)
    this.getPostData(this.currentPage)
  },
  watch: {
    '$route.params.id': function (id) {
      this.currentPage = parseInt(id, 10)
    },
    selected: function (val) {
      this.$emit('selected', val)
    }
  },
  computed: {
    headers() {
      return [
        { text: 'ID', value: 'id' },
        { text: this.$t('TABLE_HEADER.CONTRAGENT'), value: 'contragent_name' },
        { text: this.$t('TABLE_HEADER.DATE'), value: 'oper_date' },
        { text: this.$t('TABLE_HEADER.REG_NUMBER'), value: 'reg_number' },
        { text: this.$t('TABLE_HEADER.AMOUNT'), value: 'get_doc_total.total' },
        {
          text: this.$t('TABLE_HEADER.NDSAMOUNT'),
          value: 'get_doc_total.nds_summa'
        },
        {
          text: this.$t('TABLE_HEADER.NDS_AMOUNT'),
          value: 'get_doc_total.total_withnds_summa'
        },
        { text: '', value: 'action', sortable: false }
      ]
    },
    isLoading() {
      return this.$store.state.requests.isLoading
    },
    getCount() {
      return this.$store.state.requests.mainAssets
    },
    mainAssets() {
      return this.$store.state.requests.mainAssets.results
    }
  },
  methods: {
    getPostData(value) {
      if (this.$store.state.requests.filterData.data !== undefined) {
        const asd = {
          id: value,
          payload: this.$store.state.requests.filterData.data
        }
        this.$store.dispatch('contractsSearchPerPages', asd)
        if (this.$route.path !== '/allcontracts/' + value) {
          this.$router.push('/allcontracts/' + value)
        }
      } else {
        this.$store.dispatch('mainAssets', value)
        if (this.$route.path !== '/mainassetsdoc/nottaken/' + value) {
          console.log('keldi', this.$router)
          this.$router.push('/mainassetsdoc/nottaken/' + value)
        }
      }
    }
  }
}
</script>
<style scoped>
.status__name {
  border-radius: 5px;
  text-align: center;
  color: #fff;
  padding: 1px 4px;
}
.payment__name {
  font-weight: bold;
}
.v-application .elevation-1 {
  box-shadow: none !important;
}

.v-data-table /deep/ .v-data-table__wrapper > table > thead > tr > th {
  font-size: 1rem !important;
  color: #000 !important;
  font-weight: 500 !important;
}
table {
  border-collapse: collapse;
  width: 100%;
}
.v-data-table /deep/ .v-data-table__wrapper > table > tbody > tr > td {
  font-size: 1rem !important;
  color: #000 !important;
  height: 60px !important;
}
.v-data-table /deep/ .v-data-table__wrapper {
  overflow-x: scroll !important;
  white-space: nowrap !important;
  width: 100%;
  min-height: 300px;
}
</style>
